define('ember-cli-data-export/initializers/excel', ['exports'], function (exports) {
  'use strict';

  exports.initialize = initialize;

  function initialize() {
    var application = arguments[1] || arguments[0];
    application.inject('route', 'excel', 'service:excel');
    application.inject('component', 'excel', 'service:excel');
    application.inject('controller', 'excel', 'service:excel');
  }

  exports['default'] = {
    name: 'excel',
    initialize: initialize
  };
});