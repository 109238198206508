define('ember-cli-data-export/components/export-selector', ['exports', 'ember', 'ember-cli-data-export/templates/components/export-selector'], function (exports, _ember, _emberCliDataExportTemplatesComponentsExportSelector) {
  'use strict';

  exports['default'] = _ember['default'].Component.extend({
    layout: _emberCliDataExportTemplatesComponentsExportSelector['default'],
    types: _ember['default'].computed(function () {
      return [this.get('selectPrompt'), 'Excel', 'CSV'];
    }),
    selectedType: null,
    actionText: 'Export',
    selectPrompt: 'Export File',

    actions: {
      triggerExport: function triggerExport() {
        this.sendAction('exportData', this.get('selectedType'));
      }
    }
  });
});