define('ember-progress-bar/components/ember-progress-bar', ['exports', 'ember'], function (exports, _ember) {
  'use strict';

  var Component = _ember['default'].Component;
  var assign = _ember['default'].assign;
  var get = _ember['default'].get;
  var set = _ember['default'].set;
  var typeOf = _ember['default'].typeOf;

  function deepObjectAssign(object) {
    Object.keys(object).forEach(function (key) {
      if (typeOf(object[key]) === 'object') {
        object[key] = deepObjectAssign(object[key]);
      }
    });

    return assign({}, object);
  }

  exports['default'] = Component.extend({
    classNames: ['ember-progress-bar'],
    hook: 'ember_progress_bar',
    onAnimationComplete: null,

    shape: 'Line',
    options: {},

    defaultStep: function defaultStep(state, bar) {
      bar.setText((bar.value() * 100).toFixed(0));
    },

    didInsertElement: function didInsertElement() {
      this._super.apply(this, arguments);

      var shape = get(this, 'shape');
      var options = deepObjectAssign(get(this, 'options'));

      if (get(this, 'useDefaultStep')) {
        set(options, 'step', get(this, 'defaultStep'));
      }

      var progressBar = new ProgressBar[shape](this.element, options);

      set(this, 'progressBar', progressBar);
    },

    didRender: function didRender() {
      var _this = this;

      this._super.apply(this, arguments);

      var progress = get(this, 'progress');

      get(this, 'progressBar').animate(progress, function () {
        return _this.sendAction('onAnimationComplete');
      });
    },

    willDestroyElement: function willDestroyElement() {
      get(this, 'progressBar').destroy();

      this._super.apply(this, arguments);
    }
  });
});