define('ember-composable-helpers/helpers/array', ['exports', 'ember-helper', 'ember-array/utils'], function (exports, _emberHelper, _emberArrayUtils) {
  'use strict';

  exports.array = array;

  function array(params) {
    return (0, _emberArrayUtils.A)(params);
  }

  exports['default'] = (0, _emberHelper.helper)(array);
});