define('ember-composable-helpers/helpers/contains', ['exports', 'ember-array/utils', 'ember-metal/get', 'ember-utils', 'ember-composable-helpers/-private/create-needle-haystack-helper'], function (exports, _emberArrayUtils, _emberMetalGet, _emberUtils, _emberComposableHelpersPrivateCreateNeedleHaystackHelper) {
  'use strict';

  exports.contains = contains;

  function _contains(needle, haystack) {
    return (0, _emberArrayUtils.A)(haystack).contains(needle);
  }

  function contains(needle, haystack) {
    if ((0, _emberUtils.typeOf)(haystack) !== 'array') {
      return false;
    }

    if ((0, _emberUtils.typeOf)(needle) === 'array' && (0, _emberMetalGet['default'])(needle, 'length')) {
      return needle.reduce(function (acc, val) {
        return acc && _contains(val, haystack);
      }, true);
    }

    return _contains(needle, haystack);
  }

  exports['default'] = (0, _emberComposableHelpersPrivateCreateNeedleHaystackHelper['default'])(contains);
});