define('ember-maskmoney/components/imput-money', ['exports', 'ember'], function (exports, _ember) {
  'use strict';

  exports['default'] = _ember['default'].TextField.extend({
    prefix: '',
    suffix: '',
    affixesStay: false,
    thousands: ',',
    decimal: '.',
    precision: 2,
    allowZero: true,
    allowNegative: false,

    options: (function () {
      return {
        prefix: this.get('prefix'),
        suffix: this.get('suffix'),
        affixesStay: this.get('affixesStay'),
        thousands: this.get('thousands'),
        decimal: this.get('decimal'),
        precision: this.get('precision'),
        allowZero: this.get('allowZero'),
        allowNegative: this.get('allowNegative')
      };
    }).property(),

    initializeMask: (function () {
      this.$().maskMoney(this.get('options'));
      this.propertyDidChange('number');
    }).on('didInsertElement'),

    teardownMask: (function () {
      this.$().maskMoney('destroy');
    }).on('willDestroyElement'),

    setMaskedValue: (function () {
      var number = parseFloat(this.get('number') || 0).toFixed(this.get('precision'));
      var val = number.toString().replace('.', this.get('decimal'));
      this.$().val(val);
      this.$().maskMoney('mask');
    }).observes('number'),

    setUnmaskedValue: (function () {
      this.set('number', this.$().maskMoney('unmasked')[0]);
    }).observes('value')
  });
});